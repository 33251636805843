<template>
  <v-sheet class="mx-md-5 mx-2">
    <transition name="fade">
      <router-view @showParent="showParent"> </router-view>
    </transition>

    <div v-if="showParentPage">
      <v-breadcrumbs class="d-print-none">
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }">{{
          $vuetify.lang.t("$vuetify.sideBar.home")
        }}</v-breadcrumbs-item>
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item
          :exact="true"
          :to="{ name: 'inventorySelection', params: {} }"
          active
        >
          {{ $vuetify.lang.t("$vuetify.sideBar.inventorySelection") }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <v-expansion-panels class="d-print-none" v-model="showSearch">
        <v-expansion-panel>
          <v-expansion-panel-header class="d-flex justify-space-between">
            <div class="d-flex flex-column flex-md-row">
              <span>{{ $vuetify.lang.t("$vuetify.base.search") }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-sheet class="d-flex flex-column">
              <page-params
                pageName="inventorySelection"
                vuexName="labSearchParameters"
                :parameters="parameters"
                @onLoad="getSelectedProductSpecs(); search()"
              />
              <v-divider />

              <div class="my-5">
                <div class="d-flex justify-space-between">
                  <v-autocomplete
                    label="Choose a target product"
                    return-object
                    v-model="parameters.targetProduct"
                    :items="productSpecsList"
                    @input="getSelectedProductSpecs"
                    item-text="productName"
                    item-value="productId"
                    :key="ts"
                    clearable
                    outlined
                    hide-details
                  />
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="ml-2" v-bind="attrs" v-on="on" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-5">
                      <v-list-item :to="{ name: 'productSpecs' }">
                        <v-list-item-content> Review All </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="productSpecs && parameters.targetProduct"
                        :to="{
                          name: 'productSpecsEdit',
                          params: { id: productSpecs.id },
                        }"
                      >
                        <v-list-item-content>
                          Edit
                          {{ parameters.targetProduct.productName }} Specifications
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="!productSpecs && parameters.targetProduct"
                        :to="{
                          name: 'productSpecsCreate',
                          params: { productId: parameters.targetProduct.productId },
                        }"
                      >
                        <v-list-item-content>
                          Define
                          {{ parameters.targetProduct.productName }} Specifications
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <v-expansion-panels v-model="showSearchCriteria">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="d-flex justify-space-between"
                  >
                    <div class="d-flex flex-column flex-md-row">
                      <span class="mb-2 mb-md-0">Edit search criteria</span>
                      <v-divider class="mx-4" vertical />
                      <span class="font-weight-light">{{ criteriaText }}</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      class="
                        d-flex
                        flex-column flex-md-row
                        justify-space-between
                      "
                      v-for="(operation, index) in parameters.operations"
                      :key="index"
                    >
                      <v-autocomplete
                        :items="labElements"
                        v-model="operation.elementId"
                        item-text="symbol"
                        item-value="id"
                        label="Element"
                        @change="updateCriteriaText(parameters.operations)"
                      />
                      <v-btn-toggle
                        rounded
                        class="mx-auto mx-md-10"
                        v-model="operation.operator"
                        @change="updateCriteriaText(parameters.operations)"
                      >
                        <v-btn value="gt">
                          <v-icon small>mdi-greater-than</v-icon>
                        </v-btn>
                        <v-btn value="ge">
                          <v-icon small>mdi-greater-than-or-equal</v-icon>
                        </v-btn>
                        <v-btn value="eq">
                          <v-icon small>mdi-equal</v-icon>
                        </v-btn>
                        <v-btn value="le">
                          <v-icon small>mdi-less-than-or-equal</v-icon>
                        </v-btn>
                        <v-btn value="lt">
                          <v-icon small>mdi-less-than</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                      <v-text-field
                        v-model="operation.value"
                        label="Value"
                        @change="updateCriteriaText(parameters.operations)"
                      />
                      <div class="text-right">
                        <v-btn
                          class="mt-5"
                          v-if="parameters.operations.length > 1"
                          @click="parameters.operations.splice(index, 1)"
                          icon
                        >
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>

                        <v-divider class="d-flex d-md-none" />
                      </div>
                    </div>

                    <div class="d-flex justify-end">
                      <v-btn @click="parameters.operations.push({})"
                        >Add Criteria</v-btn
                      >
                    </div>

                    <div>
                      <v-radio-group
                        label="Include inventory status"
                        v-model="parameters.inventoryStatus"
                        row
                        hide-details
                        dense
                      >
                        <v-radio
                          v-for="(status, index) in inventoryStatuses"
                          :key="index"
                          :label="status.label"
                          :value="status.value"
                        ></v-radio>
                      </v-radio-group>
                    </div>

                    <div class="my-4">
                      <product-select
                        label="Filter results by products"
                        v-model="parameters.filterProducts"
                        multiple
                        return-object
                        :key="ts"
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <div class="d-flex justify-center mt-10">
                <v-btn color="primary" @click="search" :loading="loadingData">{{
                  $vuetify.lang.t("$vuetify.base.submit")
                }}</v-btn>
              </div>
            </v-sheet>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-progress-linear indeterminate color="primary" :active="loadingData" />

      <div
        id="resultPanel"
        class="table-container"
        v-if="inventoryList && inventoryList.length > 0"
      >
        <v-divider class="my-5" />

        <div class="my-2 d-flex justify-space-around d-print-none">
          <v-btn v-print="resultPanel"> Print </v-btn>
          <download
            v-if="filteredInventoryList && filteredInventoryList.length > 0"
            v-model="filteredInventoryList"
            fileName="CoA-Inventory-Selection"
          />
        </div>
        <div class="ma-5" v-if="isMobile">
          <v-menu z-index="99">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" width="100%">
                Filter Results
                <v-icon right>mdi-filter-variant</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-5">
              <v-checkbox
                label="Selected Only"
                hide-details
                v-model="filterSelection"
                value="selectedOnly"
              />
              <v-checkbox
                label="Unselected Only"
                hide-details
                v-model="filterSelection"
                value="unselectedOnly"
              />
              <v-divider />
              <v-radio-group v-model="filterSelection">
                <v-radio label="Select All" value="selectAll"></v-radio>
                <v-radio label="Unselected All" value="unselectAll"></v-radio>
              </v-radio-group>
            </v-list>
          </v-menu>
        </div>
        <v-data-table
          id="selection-results"
          :headers="headers"
          :items="filteredInventoryList"
          :items-per-page="pagination.pageSize"
          :loading="isAppLoading"
          :page="pagination.current"
          item-key="id"
          class="elevation-1 my-5"
          :disable-pagination="true"
          :hide-default-footer="true"
          height="90vh"
        >
          <template v-slot:header.selected="{}">
            <v-menu v-if="!isMobile" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
              </template>
              <v-list class="pa-5">
                <v-checkbox
                  label="Selected Only"
                  hide-details
                  v-model="filterSelection"
                  value="selectedOnly"
                />
                <v-checkbox
                  label="Unselected Only"
                  hide-details
                  v-model="filterSelection"
                  value="unselectedOnly"
                />
                <v-divider />
                <v-radio-group v-model="filterSelection">
                  <v-radio label="Select All" value="selectAll"></v-radio>
                  <v-radio label="Unselected All" value="unselectAll"></v-radio>
                </v-radio-group>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:header.documentNo="{}">
            <v-text-field
              v-model="documentNoFilter"
              class="mr-2"
              style="font-size: small; min-width: 110px"
              placeholder="Document No"
              outlined
              clearable
              dense
              hide-details
              @click.prevent.stop=""
            />
          </template>

          <template v-slot:item.selected="{ item }">
            <v-simple-checkbox
              class="ml-1"
              :ripple="false"
              :value="true"
              v-model="item.selected"
              @click="
                checkedInventory(item);
                summarize();
              "
              :key="item.selected"
            />
          </template>
          <template v-slot:item.documentNo="{ item }">
            <a
              v-if="item.inventoryId"
              @click="
                lookupInventory = true;
                selectedInventory = item.inventoryId;
              "
            >
              <text-highlight
                v-if="documentNoFilter"
                :queries="documentNoFilter"
              >
                {{ item.documentNo }}</text-highlight
              >
              <span v-else>{{ item.documentNo }}</span>
            </a>
          </template>
          <template v-slot:item.inventory.stockTime="{ item }">
            {{
              item.inventory ? item.inventory.stockTime : null | formatDateYear
            }}
          </template>
          <template v-slot:item.use="{ item }">
            <div style="width: 100px">
              <v-text-field
                @change="summarize"
                :disabled="!item.selected"
                v-model="item.use"
                outlined
                dense
                hide-details
                class="shrink my-2"
              />
            </div>
          </template>
          <template v-slot:item.inventory.quantityAvailable="{ item }">
            {{
              item.inventory
                ? item.inventory.quantityAvailable
                : null | formatNumber
            }}
          </template>

          <template v-slot:item.inventory.unitAvailable="{ item }">
            <inventory-unit-details
              v-model="item.inventory.id"
              :count="item.inventory.unitAvailable"
              :key="item.inventory.id"
              mode="selection"
              @selectionChanged="updateUse(item, $event)"
            />
          </template>

          <template slot="body.append">
            <tr
              v-if="isMobile && selectedTotal"
              class="v-data-table__mobile-table-row"
            >
              <td class="v-data-table__mobile-row">
                <div class="v-data-table__mobile-row__header">
                  {{ this.$vuetify.lang.t("$vuetify.worksheet.selectedUnit") }}
                </div>
                <div class="v-data-table__mobile-row__cell">
                  {{ selectedTotal.units | sum | formatNumber }}
                </div>
              </td>
              <td class="v-data-table__mobile-row">
                <div class="v-data-table__mobile-row__header">Selected Use</div>
                <div class="v-data-table__mobile-row__cell">
                  {{ selectedTotal.use | sum | formatNumber }}
                </div>
              </td>
              <td class="v-data-table__mobile-row">
                <div class="v-data-table__mobile-row__header">
                  Selected Quantity
                </div>
                <div class="v-data-table__mobile-row__cell">
                  {{ selectedTotal.quantities | sum | formatNumber }}
                </div>
              </td>
              <template v-if="productSpecs">
                <td
                  class="v-data-table__mobile-row"
                  v-for="e in productSpecs.elements"
                  :key="e.id"
                >
                  <div class="v-data-table__mobile-row__header">
                    {{ e.element.elementName }} ({{ e.element.symbol }})
                  </div>
                  <div class="v-data-table__mobile-row__cell">
                    {{
                      weightedAvg(selectedTotal[e.element.symbol])
                        | negativeNumber
                    }}
                  </div>
                </td>
              </template>
            </tr>

            <tr class="table-summary" v-if="selectedTotal && !isMobile">
              <td colspan="2">Selected Total</td>
              <td colspan="2"></td>
              <td>
                {{ selectedTotal.units | sum | formatNumber }}
              </td>
              <td>
                {{ selectedTotal.use | sum | formatNumber }}
              </td>
              <td>
                {{ selectedTotal.quantities | sum | formatNumber }}
              </td>
              <template v-if="productSpecs">
                <td v-for="e in productSpecs.elements" :key="e.id">
                  {{
                    weightedAvg(selectedTotal[e.element.symbol])
                      | negativeNumber
                  }}
                </td>
              </template>
            </tr>
            <tr class="table-summary" v-if="!isMobile">
              <td colspan="2">
                {{
                  parameters.targetProduct ? parameters.targetProduct.productName : ""
                }}
                Target
              </td>
              <td colspan="2"></td>
              <td>
                <div style="width: 100px">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    class="shrink my-2"
                  />
                </div>
              </td>
              <td>0</td>
              <td>0</td>
              <template v-if="productSpecs">
                <td v-for="e in productSpecs.elements" :key="e.id">
                  {{ productSpecs[e.element.symbol] }}
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <confirmation-dialog ref="confirm" />
    <v-bottom-sheet scrollable v-model="lookupInventory">
      <v-card>
        <v-card-actions class="justify-center">
          <v-btn class="mt-6" text @click="lookupInventory = false">
            {{ $vuetify.lang.t("$vuetify.close") }}
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <inventory-details
            :key="selectedInventory"
            v-model="selectedInventory"
          />
        </v-card-text>
      </v-card>
      <!-- <v-sheet class="text-center" style="max-height: 380px; overflow: scroll">
      </v-sheet> -->
    </v-bottom-sheet>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const ProductSelect = () => import("@/components/product/ProductSelect.vue");
const PageParams = () => import("@/components/common/PageParams.vue");
import { sum } from "simple-statistics";
const InventoryDetails = () =>
  import("@/components/inventory/InventoryDetails.vue");
const InventoryUnitDetails = () =>
  import("@/components/inventory/InventoryUnitDetails.vue");
import TextHighlight from "vue-text-highlight";
import print from "vue-print-nb";
const Download = () => import("@/components/common/Download.vue");

export default {
  directives: {
    print,
  },
  data() {
    return {
      ts: Date.now(),
      productSpecsList: [],
      pagination: {
        current: 1,
        pageSize: 1000,
        totalSize: 100,
      },
      loadingData: false,
      lookupInventory: false,
      selectedInventory: null,
      inventoryList: [],
      filteredInventoryList: [],
      selectedTotal: null,
      showParentPage: true,
      showSearchCriteria: null,
      showSearch: 0,
      productSpecs: null,
      filterSelection: null,
      documentNoFilter: null,
      labElements: [],
      inventoryStatus: 0,
      inventoryStatuses: [
        { label: "Available", value: "AVAILABLE" },
        { label: "Not Available", value: "NOT_AVAILABLE" },
        { label: "All", value: "ALL" },
      ],
      headers: [],
      baseHeaders: [
        {
          text: "",
          value: "selected",
          align: "start",
          sortable: false,
        },
        {
          text: "Document No",
          value: "documentNo",
          align: "start",
          sortable: true,
        },
        {
          text: "Stock Time",
          value: "inventory.stockTime",
          align: "start",
          sortable: true,
        },
        {
          text: "Product Name",
          value: "inventory.productName",
          align: "start",
          sortable: false,
        },
        {
          text: "Unit Available",
          value: "inventory.unitAvailable",
          align: "start",
          sortable: true,
        },
        {
          text: "Use Quantity",
          value: "use",
          align: "start",
          sortable: false,
        },
        {
          text: "Quantity Available",
          value: "inventory.quantityAvailable",
          align: "start",
          sortable: true,
        },
      ],
      sort: {
        key: null,
      },
      criteriaText: "",
    };
  },
  components: {
    PageParams,
    ProductSelect,
    InventoryDetails,
    InventoryUnitDetails,
    TextHighlight,
    Download,
  },
  mounted() {
    this.fetchProductSpecs({
      page: 0,
      size: 100000,
    }).then((response) => {
      if (response) {
        this.productSpecsList = response.content;
        if(this.productSpecsList){
          this.productSpecsList.forEach(p => {
            p.elements = null
          })
        }
      }
    });

    this.fetchLabElements({ page: 0, size: 300 }).then((resp) => {
      this.labElements = resp.content;
      this.setLabElements(this.labElements);

      this.updateCriteriaText(this.parameters.operations);
    });

    if (this.parameters.targetProduct) {
      this.getSelectedProductSpecs();
    } else {
      this.prepHeaders();
    }
  },
  computed: {
    ...mapGetters("inventoryLabResult", [
      "inventoryLabResultProcessed",
      "getInventoryLabResults",
    ]),
    ...mapGetters("productSpecs", ["getProductSpecs"]),
    ...mapGetters("navigation", ["isAppLoading"]),

    parameters: {
      get() {
        return this.$store.state.labSearchParameters.parameters;
      },
      set(value) {
        this.updateParameters(value);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  filters: {
    negativeNumber: function (value) {
      if (value && value > 0) {
        return value;
      } else if (value < 0) {
        return `<${Math.abs(value)}`;
      } else {
        return 0;
      }
    },
    sum: function (array) {
      return array && array.length > 0 ? sum(array) : 0;
    },
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("inventoryLabResult", [
      "deleteInventoryLabResult",
      "fetchInventoryLabResults",
      "updateInventoryLabResult",
    ]),
    ...mapActions("labElement", ["fetchLabElements"]),
    ...mapActions("labSearchParameters", [
      "updateParameters",
      "setLabElements",
    ]),
    ...mapActions("navigation", ["hideDrawer"]),
    ...mapActions("productSpecs", ["fetchProductSpecs"]),

    view(item) {
      this.$router.push({
        name: "inventoryLabResultView",
        params: { id: item.id },
      });
    },
    edit(item) {
      this.$router.push({
        name: "inventoryLabResultEdit",
        params: { id: item.id },
      });
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this inventory lab result?"
      );
      if (confirm) {
        this.deleteInventoryLabResult(item.id)
          .then(() => {
            this.addSuccessMessage("Inventory lab result deleted");
            this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
    async getResources() {
      this.loadingData = true;

      const params = {
        page:
          this.pagination.pageIndex && this.pagination.pageIndex > 0
            ? this.pagination.pageIndex - 1
            : 0,
        size: this.pagination.pageSize,
        sort: "testTime,desc",
      };
      const criteria = {
        product_ids: this.parameters.filterProducts
          ? this.parameters.filterProducts.map((p) => p.id)
          : null,
        number_compare_operations: this.parameters.operations,
        inventory_status: this.parameters.inventoryStatus,
      };
      return await this.$axios
        .post("/inventory-lab-result/search", criteria, { params })
        .then((response) => {
          if (response.data) {
            this.pagination.totalSize = response.data.totalElements
              ? response.data.totalElements
              : 0;
            this.inventoryList = response.data.content;

            this.inventoryList.forEach((item) => {
              item.elements.forEach((element) => {
                item[element.element.symbol] = `${element.lessThan ? "<" : ""}${
                  element.compositionPercent
                }`;
              });
            });

            this.inventoryList.sort(
              (a, b) => a.inventory.stockTime > b.inventory.stockTime
            );

            this.filteredInventoryList = this.inventoryList;
            return this.inventoryList;
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);

          return [];
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    showParent(show) {
      this.showParentPage = show;
    },
    search() {
      this.showSearchCriteria = null;
      this.getResources().then(() => {
        if (this.inventoryList && this.inventoryList.length > 0) {
          this.summarize();

          this.showSearch = null;
          this.hideDrawer();
        } else {
          this.addErrorMessage("No matching inventory found");
        }
      });
    },
    clearSearch() {
      this.searchTerms = null;
      this.search();
    },
    async getSelectedProductSpecs() {
      this.inventoryList = [];

      if (this.parameters.targetProduct) {
        await this.$axios
          .get("/product-specs/by-product/" + this.parameters.targetProduct.productId)
          .then((response) => {
            this.productSpecs = response.data;
          })
          .catch((error) => {
            console.log("error, ", error);
            this.productSpecs = null;

            this.addErrorMessage(
              `${this.parameters.targetProduct.productName} specs has not been defined`
            );
          });

        this.prepHeaders();
        // this.constructOperationsFromProductSpecs();
      }
    },
    prepHeaders() {
      this.headers = null;
      const headers = [...this.baseHeaders];
      const uniqueElements = new Set();

      if (this.productSpecs) {
        this.productSpecs.elements.forEach((element) => {
          const symbol = element.element.symbol;
          uniqueElements.add(symbol);

          this.productSpecs[symbol] = this.printElementSpec(element);
        });
      }

      uniqueElements.forEach((symbol) => {
        headers.push({
          text: symbol,
          value: symbol,
          align: "start",
          sortable: true,
        });
      });

      this.headers = headers;
    },
    printElementSpec(element) {
      if (element.minPercent && !element.maxPercent) {
        return `${element.minPercent}+`;
      }
      if (!element.minPercent && element.maxPercent) {
        return `<${element.maxPercent}`;
      } else if (element.minPercent && element.maxPercent) {
        return `${element.minPercent} - ${element.maxPercent}`;
      }
    },
    getInventoryComposition(item, elementId) {
      const match = item.elements.find((e) => e.id == elementId);
      return match ? match.compositionPercent : null;
    },
    checkedInventory(row) {
      if (row.selected && (!row.use || row.use == 0)) {
        // row.use = row.inventory.quantityAvailable;
        this.$set(row, "use", row.inventory.quantityAvailable);
      }

      if (!row.selected) {
        // row.use = 0;
        this.$set(row, "use", 0);
      }
    },
    updateUse(row, { inventoryId, units }) {
      const rowIndex = this.filteredInventoryList.findIndex(
        (i) => i.inventoryId == inventoryId
      );
      const selectedSum = units.reduce(
        (sum, u) => (sum += u.selected ? u.amountPerUnit * u.unitShare : 0),
        0
      );
      // row.use = selectedSum;
      this.$set(this.filteredInventoryList[rowIndex], "use", selectedSum);
      console.log(
        "updating row: ",
        row.documentNo,
        " to ",
        selectedSum,
        inventoryId
      );

      // const src = this.inventoryList.find(i => i.inventoryId == inventoryId);
      // src.use = selectedSum;
      // this.$set(src, 'use', selectedSum);
    },
    summarize() {
      console.log("summarizing...");
      console.log(this.inventoryList);
      const selected = this.inventoryList.filter((i) => i.selected);
      const total = selected.reduce((sum, item) => {
        if (isNaN(item.use)) {
          item.use = 0;
        }

        if (sum) {
          item.elements.forEach((e) => {
            sum[e.element.symbol].push(e.compositionPercent);
          });

          sum.quantities.push(
            item.inventory ? item.inventory.quantityAvailable : 0
          );
          sum.units.push(item.inventory ? item.inventory.unitAvailable : 0);

          sum.use.push(item.inventory ? parseFloat(item.use) : 0);
        } else {
          sum = {
            quantities: [item.inventory ? item.inventory.quantityAvailable : 0],
            units: [item.inventory ? item.inventory.unitAvailable : 0],
            use: [item.use ? parseFloat(item.use) : 0],
          };
          item.elements.forEach((e) => {
            sum[e.element.symbol] = [e.compositionPercent];
          });
        }
        return sum;
      }, null);
      //calculate use weight
      // if(total && total.quantities && total.quantities.length > 0) {
      // const totalUse = sum(total.use);
      // total.useWeight = total.use.map( n => totalUse && totalUse != 0 ? n/totalUse:0 );
      // }

      this.selectedTotal = total;
    },
    weightedAvg(array) {
      if (array && array.length > 0) {
        const weightedArray = [];
        array.forEach((val, index) => {
          weightedArray.push(val * this.selectedTotal.use[index]);
        });
        return (sum(weightedArray) / sum(this.selectedTotal.use)).toFixed(6);
      } else {
        return 0;
      }
    },
    statusChanged() {
      if (
        this.inventoryStatus > -1 &&
        this.inventoryStatuses[this.inventoryStatus]
      ) {
        this.parameters.inventoryStatus =
          this.inventoryStatuses[this.inventoryStatus].value;
      }
    },
    filter(selectionState) {
      if (!selectionState) {
        this.filteredInventoryList = this.inventoryList;
      }

      if (selectionState == "selectAll") {
        this.inventoryList.forEach((i) => {
          this.$set(i, "selected", true);
          i.use = i.inventory.quantityAvailable;
        });
        this.filteredInventoryList = this.inventoryList;
        this.summarize();
      }
      if (selectionState == "unselectAll") {
        this.inventoryList.forEach((i) => {
          this.$set(i, "selected", false);
          i.use = 0;
        });
        this.filteredInventoryList = this.inventoryList;
        this.summarize();
      }

      if (selectionState == "selectedOnly") {
        this.filteredInventoryList = this.inventoryList.filter((i) => {
          return i.selected;
        });
      }

      if (selectionState == "unselectedOnly") {
        this.filteredInventoryList = this.inventoryList.filter((i) => {
          return !i.selected;
        });
      }

      if (this.documentNoFilter && this.documentNoFilter.trim() !== "") {
        this.filteredInventoryList = this.filteredInventoryList.filter((i) => {
          return i.documentNo
            .toLowerCase()
            .includes(this.documentNoFilter.toLowerCase());
        });
      }

      return this.filteredInventoryList;
    },
    updateCriteriaText(operations) {
      if (operations && operations.length > 0) {
        let text = "";
        const operationsWithVal = operations.filter(
          (o) => o.value && o.elementId
        );
        console.log("with val: ", operationsWithVal);

        operationsWithVal.forEach((operation, index) => {
          const element = this.labElements.find(
            (e) => e.id == operation.elementId
          );
          let operator = operation.operator;
          switch (operator) {
            case "ge":
              operator = ">=";
              break;
            case "le":
              operator = ">=";
              break;
            case "eq":
              operator = "=";
              break;
            case "lt":
              operator = "<";
              break;
            case "gt":
              operator = ">";
              break;
          }
          if (element) {
            text += ` ${element.symbol} ${operator} ${operation.value} `;
          }

          text += index < operationsWithVal.length - 1 ? " and " : "";
        });

        this.criteriaText = text;
      } else {
        this.criteriaText = "";
      }
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
    documentNoFilter(val) {
      this.filter(this.filterSelection, val);
    },
    filterSelection(val) {
      this.filter(val);
    },
    "parameters.targetProduct": function (val) {
      if (!val) {
        this.showSearchCriteria = 0;
      }
    },
    "parameters.operations": function (operations) {
      this.updateCriteriaText(operations);
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.getResources();
    }
    next();
  },
};
</script>
<style scoped>
.nowrap {
  white-space: nowrap;
}

.table-summary {
  background-color: beige;
}
</style>

<style>
@media only screen and (min-width: 480px) {
  #selection-results thead th,
  #selection-results tbody td {
    white-space: nowrap;
  }

  #selection-results thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: white;
  }

  #selection-results thead th:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0.95;
    background-color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
  #selection-results thead th:nth-child(2) {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 72px;
    top: 0;
    z-index: 10;
    opacity: 0.95;
    background-color: white;
  }
  #selection-results thead.v-data-table-header > tr > th:nth-child(2) > div {
    display: inline-block;
    width: 90%;
  }

  #selection-results tbody tr:not(.table-summary) td:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    z-index: 9;
    opacity: 0.95;
    background-color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }

  #selection-results tbody tr:not(.table-summary) td:nth-child(2) {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 72px;
    z-index: 9;
    opacity: 0.95;
    background-color: white;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #selection-results .table-summary td {
    background-color: beige;
  }

  #selection-results .table-summary td:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    z-index: 9;
    opacity: 0.95;
    background-color: beige;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
